<template lang="pug">
.page
  .loginBox
    el-form(label-width="4em")
      el-form-item(label="账号")
        el-input(v-model="loginData.account")
      el-form-item(label="密码")
        el-input(v-model="loginData.password" type="password")
      el-form-item
        el-button(type="primary" style="width:100%" @click="handleLoginByPassword") 登陆

</template>

<script setup>
import { inject, onMounted, ref, defineComponent } from 'vue'
import { userInfo } from '@/provider/account'

const { fetch, message, router } = inject('global')

const loginData = ref({
  account: '',
  password: ''
})

function handleLoginByPassword() {
  if (!loginData.value.account) {
    message.warning('账号不能为空')
  } else if (!loginData.value.password) {
    message.warning('密码不能为空')
  } else {
    fetch
      .post('/account/loginByPassword', { ...loginData.value })
      .then(res => {
        localStorage.token = res.token
        userInfo.value = res.userInfo
        message.success('登陆成功')
        router.push('/')
      })
      .catch(err => {
        message.error(err?.msg || err)
      })
  }
}
</script>
<script>
export default defineComponent({
  layout: 'fullPage'
})
</script>

<style lang="less" scoped>
.page {
  .flexCenter();
  width: 100%;
  height: 100vh;
}
.loginBox {
  background: white;
  padding: 20px;
  border-radius: 20px;
}
</style>
